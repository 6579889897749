<template>
  <div class="deposit_edit world">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="paddB10">
      <el-breadcrumb-item :to="{ path: '/enterprise/centre/lanzhou/deposit/edit' }" class="bs_text_oo">
        返回上一级<span> | </span>残保金审核
      </el-breadcrumb-item>
      <el-breadcrumb-item class="color66">新增</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content backgroundWhite">
      <h1 class="font18 bold">按比例安置残疾人就业保障金年审申请</h1>
      <ul class="tab flex">
        <li v-for="(n,i) in tabs" :key="i" :class="i === 1?'active':''">{{ n }}</li>
      </ul>
      <div class="container next_two">
        <div class="tip marT50 marB20" v-if="tableData.length <= 0">
          <p>说明：1、图片可以是扫描件或照片，每张图片大小不超过2MB，为了不影响审核结果，请保证图片内容清晰可见。 </p>
          <p> 2、劳动合同需加盖本单位公章。</p>
        </div>
        <div class="tables">
          <div class="add text_r">
            <button class="bs_btn bs_text_oo" @click="add"><img src="~/static/center/img_add.png" alt=""
                                                                class="middle marR5"><span>新增</span>
            </button>
          </div>

          <el-table

              class="text_c"
              :data="tableData"
              stripe
              style="width: 100%">
            <el-table-column
                prop="name"
                align="center"
                label="姓名">
            </el-table-column>
            <el-table-column
                prop="sex"
                align="center"
                label="性别">
            </el-table-column>
            <el-table-column
                prop="idNumber"
                align="center"
                width="200"
                label="身份证号">
            </el-table-column>
            <el-table-column
                prop="disabledNumber"
                align="center"
                width="200"
                label="残疾证号">
            </el-table-column>
            <el-table-column
                align="center"
                prop="distabledType"
                label="残疾类型">
            </el-table-column>
            <el-table-column
                align="center"
                prop="distabledLevel"
                label="残疾等级">
            </el-table-column>
            <el-table-column
                align="center"
                width="180px"
                label="操作">
              <template slot-scope="scope">
                <button class="b_btn bs_text_oo paddR5 paddL5" @click="edit(scope.row.id,'1')">
                  详情
                  <i class="line"></i>
                </button>
                <button class="b_btn bs_text_oo paddR5 paddL5" @click="edit(scope.row.id,'2')">
                  编辑
                  <i class="line"></i>
                </button>
                <button class="b_btn bs_text_oo paddL5" @click="delte(scope.row.id)">删除
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            class="work_pagination"
            :current-page.sync="form.pageNum"
            :page-size="form.pageSize"
            layout="prev, pager, next, jumper"
            prev-text="上一页"
            next-text="下一页"
            :total="form.total">
        </el-pagination>
        <div class="buttons_add" v-if="tableData.length <= 0">
          <el-button type="primary" class="w170" @click="add">添加</el-button>
          <div class="tips">
            点击保存，信息会存为草稿，提交前均可编辑，确保信息无误后再提交。
          </div>
        </div>
        <div class="buttons_add" v-else>
          <el-button type="primary" class="w170" @click="goRouter('/enterprise/centre/lanzhou/deposit/edit')">上一步</el-button>
          <el-button type="primary" class="w170" @click="goRouter('/enterprise/centre/lanzhou/deposit/nextEdit1')">下一步</el-button>
          <div class="tips">
            点击保存，信息会存为草稿，提交前均可编辑，确保信息无误后再提交。
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="残疾人就业信息"
        :visible.sync="isShow"
        width="880px"
        :destroy-on-close="true"
        @closed="getAlldisable">
      <depositEdit v-bind:id="disableid" @updateDisable="updateDisable" :type="editType"></depositEdit>
    </el-dialog>
  </div>
</template>

<script>
import depositEdit from '@/components/lanzhou-cdpe/depositEdit'

export default {
  name: "nextEdit",
  components: {
    depositEdit,
  },
  data(){
    return{
      tabs: ['单位信息', '安置残疾人就业信息', '全员工资表', '就业统计表', '社保缴费证明', '医保缴费证明'],
      tableData:[],
      form:{
        pageNum:1,
        pageSize:10,
        total:0
      },
      isShow:false,
      editType: '1',
      disableid:'',
    }
  },
  methods:{
    getAlldisable: function () {
      this.$api.lanzhouGetDisabledsApi(this.form).then(response => {
        this.tableData = response.data.data.disabledPrimumChecks.list;
        this.total = response.data.data.disabledPrimumChecks.total;
      })
    },
    handleCurrentChange: function (val) {
      this.form.pageNum = val;
      this.getAlldisable();
    },
    edit(id,type){
      this.disableid = id;
      this.editType = type;
      this.isShow = true;
    },
    delte(id) {
      this.$api.lanzhouDeleteDisableApi(id).then(response => {
        if (response.data.success) {
          this.$message.success(`删除成功`);
          this.getAlldisable();
        }
      })
    },
    add() {
      this.disableid = '';
      this.editType = '2';
      this.isShow = true;
    },
    updateDisable(){
      this.isShow = false;
      this.getAlldisable();
    },
    goRouter(url){
      this.$router.push(url);
    },
  },
  created() {
      this.getAlldisable();
  }
}
</script>

<style scoped lang="less">
@import "~assets/lanzhou/enterprise.less";
@import "~assets/lanzhou/deposit.less";
</style>